import Vue from "vue";
import Components from "./components";
import "./app.scss";
import '@fortawesome/fontawesome-free/js/all.js';

import ClickOutside from 'vue-click-outside'

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  data(){
    return {
      isOpen: false,
    }
  },

  directives: {
    ClickOutside
  },
  methods:{
    toggle () {
      this.isOpen = !this.isOpen
    },
 
    hide () {      
      this.isOpen = false
    }
  }
});